/* eslint react-hooks/exhaustive-deps: 0 */

import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { RejectMessage } from '../../../components/ModalMessage';
import Mount from '../../../components/Mount';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import { Spinner } from '../../../components/UI/Spinner';
import sendEventToGTM from '../../../helpers/tagManager';
import basicInfoGet from '../../../services/basicInfoGet';
import loanDesistPost from '../../../services/loanDesistPost';
import { checkCreditHistory } from '../../../store/actions/checkCreditHistory';
import {
  ResolveNextPath,
  resolveNextPath,
} from '../../../store/actions/navigation';
import { asyncDispatch } from '../../../store/asyncDispatch';
import { JTPState } from '../../../store/index';
import { disclaimer } from '../../../store/reducers/disclaimerReducer';
import styled from '../../../styles/styled-components';

const ErrorMessage = {
  main: (
    <div>
      <p>
        Lo sentimos! En este momento no ha sido posible finalizar tu solicitud
        de crédito.
      </p>
      <p>
        Inicia sesión nuevamente para continuar tu solicitud o comunícate con
        nosotros a:
      </p>
      <p>
        <b>WhatsApp: </b>
        <a
          href="https://wa.me/5744807833"
          rel="noopener noreferrer"
          target="_blank"
          title="WhatsApp"
        >
          https://wa.me/5744807833
        </a>
      </p>
      <p>
        <b>Línea gratuita nacional: </b> 018000413811
      </p>
      <p>
        <b>Correo: </b> ayuda@juanchotepresta.com
      </p>
    </div>
  ),
  accept: 'Salir',
  noClosable: true,
};
const ErrorNoRequest= {
  main: (
    <div>
      <p>
        <strong>En este momento, no podemos atender tu solicitud. </strong> 
      </p>
      <p>
      ¡Prometemos avisarte pronto para que vuelvas a entrar y acercarte a tus sueños con Juancho!
      </p>
    </div>
  ),
  accept: 'Salir',
  noClosable: true,
};

interface Props extends RouteComponentProps {
  checkCreditHistory(): Promise<{ userFound: boolean; hasBuro: boolean }>;
  resolveNextPath: ResolveNextPath;
  loans: any;
  product:string;
  simUserMsg:string;
  submit(payload: { jobType: number }): Promise<void>;
}

const LoadingStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .desc {
    padding-bottom: 80px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
    font-size: 20px;
    color: ${(props) => props.theme.gray500};
  }
`;

const RedirectComp: React.FC<{ next: string }> = ({ next }) => {
  return <Redirect to={next} />;
};

const msjs = [
  'Vamos a procesar la información. Esto puede tardar un par de minutos. No abandones ni recargues la página.',
  'Para nosotros lo más importante es tu progreso. Estamos analizando cómo podemos apoyarte.',
  'Un momento más, nuestro sistema está completando el proceso.',
];

const CheckCreditHistoryView: React.FC<Props> = ({
  checkCreditHistory,
  resolveNextPath,
  loans,
  product,
  simUserMsg,
  submit,
  ...routeProps
}) => {
  const [modal, openModal] = useModalC();
  const [msjLoading, setMsjLoading] = useState<String>(msjs[0]);
  
  const timer = React.useCallback((conteo) => {
    setTimeout(() => {
      let con = conteo === 2 ? 0 : conteo + 1;
      setMsjLoading(msjs[conteo]);
      timer(con);
    }, 3000);
  }, []);

  React.useEffect(() => {
    timer(0);
  }, []);

  const before = useCallback(async () => {
    try {
      timer(0);
      const bi = (await basicInfoGet()).payload;
      // console.log("basic information ",bi.jobType)
      const action = {
        jobType:bi.jobType
      }
      await submit(action);
      await checkCreditHistory();
      return await resolveNextPath();
      
    } catch (e) {
      if (e.message === 'Loan was rejected') {
        const event =
          Object.values(loans || []).length > 1
            ? 'RechazoRecurrente'
            : 'RechazoNuevo';
        sendEventToGTM(event);
        await openModal(RejectMessage);
      }
      else if
      (e.code === 418) {
        try {
          await loanDesistPost();
        } catch { }
        await openModal(ErrorNoRequest);
      }
      else {
        await openModal(ErrorMessage);
      } 
     
      return { next: '/logout' };
    }
  }, []);
  //console.log('product ==>',product)
  return (
    <>
      <LoadingStyled>
        <Spinner size={64} />
        <div className="desc">{msjLoading}</div>
      </LoadingStyled>
      <ModalC props={modal}/>
      <Mount
        before={before}
        // loadingComponent={() => LoadingComponent(msjLoading)}
        props={routeProps}
        component={RedirectComp}
      />
    </>
  );
};

export default connect(
  (state: JTPState, ownProps) => ({
    loans: state.loans,
    product:state.loan.product,
    simUserMsg:state.loan.simUserMsg
  }),
  (dispatch) => ({
    submit: asyncDispatch(dispatch, disclaimer.actions.request),
    checkCreditHistory: asyncDispatch(dispatch, checkCreditHistory),
    resolveNextPath: asyncDispatch(dispatch, resolveNextPath),
  })
)(CheckCreditHistoryView);
