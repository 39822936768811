import { Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { NumberSchema, number, string } from 'yup';
import Form from '../../../components/Form/Form';
import FormChange from '../../../components/Form/FormChange';
import FormGroup from '../../../components/Form/FormGroup';
import FormSubmitPI from '../../../components/Form/FormSubmitPI';
import FormSubmitPro from '../../../components/Form/FormSubmitPro';
import InputDate from '../../../components/Form/InputDate';
import InputGroup from '../../../components/Form/InputGroup';
import InputNumber from '../../../components/Form/InputNumber';
import InputSelect from '../../../components/Form/InputSelect';
import Label from '../../../components/Form/Label';
import Title from '../../../components/Form/Title';
import Column from '../../../components/Grid/Column';
import Button from '../../../components/UI/Button';
import ButtonContainer from '../../../components/UI/ButtonContainer';
import GeneralContent from '../../../components/UI/GeneralContent';
import Wizard from '../../../components/Wizard';
import { submitTrap } from '../../../helpers/formHelpers';
import moneyFormat from '../../../helpers/moneyFormat';
import preventDefault from '../../../helpers/preventDefault';
import UseCities from '../../../hooks/UseCities';
import { FormFieldsError } from '../../../services/FormFieldsError';
import { BasicInfo } from '../../../services/basicInfoPost';
import {
  Selectors,
  findOption,
  labelToValue,
} from '../../../services/selectorsGet';
import validationSchema from './ValidationSchema';
const breakpointLarge = '700px';
const isNone = (v: string) => v.toLowerCase().includes('ningun');

const SpecialLaber = styled.label`
  color: #38761d;
`;
const GroupButton = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  width:100%
  flex-direction:column;
  @media (min-width: ${breakpointLarge}) {
    flex-direction:row;
  }
`
const MessageEnd = styled.div`
  padding:0;
  width:100%;
  text-align: justify;
  @media (min-width: ${breakpointLarge}) {
    padding:25px 25px 0 25px;
  }
`
export interface Props {
  initialValues: BasicInfo;
  mifos: Selectors;
  minIncome: Record<string, number>;
  departments: Record<'label' | 'value', string>[];
  submit(values: BasicInfo): Promise<void>;
}

const PersonalInformationView: React.FC<Props> = ({
  initialValues,
  mifos,
  departments,
  minIncome,
  submit,
}) => {
  const [formValues, setFormValues] = useState<BasicInfo>(initialValues);

  const [citiesPersonal, setCitiesPersonal] = UseCities(
    initialValues.personal.issueDepartmentCode
  );
  const [citiesResidential, setCitiesResidential] = UseCities(
    initialValues.residential.departmentCode
  );
  
  const wizard = Wizard.useWizard(3);

  const dependents = [
    { value: 'Ninguna' },
    { value: 'Entre 1 y 3' },
    { value: 'Mas de 3' },
  ];

  const employee = labelToValue(mifos.jobType, 'Empleado', 'Empleado temporal');

  const independent = labelToValue(mifos.jobType, 'Independiente');

  const unemployed = labelToValue(mifos.jobType, 'Desempleado');

  const pensioner = labelToValue(mifos.jobType, 'Pensionado');

  const student = labelToValue(mifos.jobType, 'Estudiante');
  return (
    <Column fluid>
      <GeneralContent>
        <Wizard.Tracker wizard={wizard} />
        <Wizard.Steps wizard={wizard}>
          <Wizard.Step step={1}>
            <Formik
              validateOnBlur
              initialValues={initialValues.personal}
              validationSchema={validationSchema.personal}
              onSubmit={submitTrap((personal) => {
                if (!personal.issueDepartmentCode) {
                  throw new FormFieldsError({ issueDepartmentCode: 'Requerido' });
                }
                if (!personal.issueCityCode){
                  throw new FormFieldsError({ issueCityCode: 'Requerido' });
                }
                const stringDepartamentCode= personal.issueDepartmentCode.toString();
                const stringCityCode= personal.issueCityCode.toString();
                const sliceDepartamentCod = stringDepartamentCode.slice(0, 2);
                const sliceCityCode = stringCityCode.slice(0, 2);
                if(sliceDepartamentCod!==sliceCityCode){
                  throw new FormFieldsError({ issueCityCode: 'Requerido' });
                }

                setFormValues((prev) => ({ ...prev, personal }));
                wizard.setStep(2);
              })}
            >
              <Form>
                <Title divider>Información personal</Title>
                <GeneralContent>
                  <FormGroup>
                    <Label>
                      Fecha de expedición del documento de identidad
                    </Label>
                    <SpecialLaber>
                      <small>
                        Asegúrate de que la <b>fecha</b> sea correcta, sino tu
                        solicitud se verá interrumpida{' '}
                      </small>
                    </SpecialLaber>
                    <InputDate name="expeditionDate" />
                  </FormGroup>

                  <FormGroup>
                    <Label>Departamento de expedición del documento</Label>
                    <InputSelect
                      name="issueDepartmentCode"
                      options={departments.slice()
                        .sort((a, b) => a.label.localeCompare(b.label))}
                      onChange={(code: string) => {
                        setCitiesPersonal(code);
                      }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Ciudad de expedición del documento</Label>
                    <InputSelect
                      name="issueCityCode"
                      options={citiesPersonal}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Fecha de nacimiento</Label>
                    <InputDate name="birthdate" />
                  </FormGroup>

                  <FormGroup>
                    <Label>Sexo</Label>
                    <InputSelect name="genderId" options={mifos.genderId} />
                  </FormGroup>

                  <FormGroup>
                    <Label>
                      ¿Cuántas personas dependen de ti económicamente?
                    </Label>
                    <InputSelect name="dependents" options={dependents} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Nivel de estudios</Label>
                    <InputSelect
                      name="educationalLevel"
                      options={mifos.educationalLevel}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Estado civil</Label>
                    <InputSelect
                      name="maritalStatus"
                      options={mifos.maritalStatus}
                    />
                  </FormGroup>
                </GeneralContent>

                <ButtonContainer>
                  <FormSubmitPro>Siguiente</FormSubmitPro>
                </ButtonContainer>
              </Form>
            </Formik>
          </Wizard.Step>

          <Wizard.Step step={2}>
            <Formik
              validateOnBlur
              initialValues={initialValues.residential}
              validationSchema={validationSchema.residential}
              onSubmit={submitTrap((residential) => {
                if (!residential.departmentCode) {
                  throw new FormFieldsError({ departmentCode: 'Requerido' });
                }
                if (!residential.cityCode){
                  throw new FormFieldsError({ cityCode: 'Requerido' });
                }
                const stringDepartamentCode= residential.departmentCode.toString();
                const stringCityCode= residential.cityCode.toString();
                const sliceDepartamentCod = stringDepartamentCode.slice(0, 2);
                const sliceCityCode = stringCityCode.slice(0, 2);
                if(sliceDepartamentCod!==sliceCityCode){
                  throw new FormFieldsError({ cityCode: 'Requerido' });
                }
                residential.neighborhood = residential.neighborhood.replace(/\t/g, '');
                residential.address = residential.address.replace(/\t/g, '');
                setFormValues((prev) => ({
                  ...prev,
                  residential: { ...residential },
                }));
                wizard.setStep(3);
              })}
            >
              <Form>
                <Title divider>Información residencial</Title>
                <GeneralContent>
                  <FormGroup>
                    <Label>Departamento</Label>
                    <InputSelect
                      name="departmentCode"
                      placeholder="Selecciona tu departamento"
                      options={departments.slice()
                        .sort((a, b) => a.label.localeCompare(b.label))}
                      onChange={(code) => {setCitiesResidential(code);
                      }}
                    />
                    <Label>Ciudad</Label>
                    <InputSelect
                      name="cityCode"
                      placeholder="Selecciona tu ciudad"
                      options={citiesResidential}
                    />
                    <InputGroup label="Barrio" name="neighborhood" />
                    <InputGroup
                      label="Dirección de residencia"
                      name="address"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Tipo de residencia</Label>
                    <InputSelect
                      name="housingType"
                      options={mifos.housingType}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Tiempo de permanencia en la residencia</Label>
                    <InputSelect name="stayTime" options={mifos.stayTime} />
                  </FormGroup>
                </GeneralContent>

                <GroupButton>
                  <ButtonContainer>
                    <Button flat onClick={preventDefault(wizard.decrease)}>
                      Anterior
                    </Button>
                  </ButtonContainer>
                  <ButtonContainer>
                    <FormSubmitPro>
                      Siguiente
                    </FormSubmitPro>
                  </ButtonContainer>
                </GroupButton>
              </Form>
            </Formik>
          </Wizard.Step>

          <Wizard.Step step={3}>
            <Formik
              validateOnBlur
              initialValues={initialValues.laboral}
              validationSchema={validationSchema.laboral.shape(
                {
                jobType: number().required('Requerido'),
                company: string().when('jobType', {
                  is: (v) => employee.includes(Number(v)),
                  then: string().required('Requerido'),
                  otherwise: string(),
                }),
                nit: string().when('jobType', {
                  is: (v) => independent.includes(Number(v)),
                  then: string(),
                  otherwise: string(),
                }),
                economicActivity: string().when('jobType', {
                  is: (v) => independent.includes(Number(v)),
                  then: string().required('Requerido'),
                  otherwise: string(),
                }),
                bank: string().when('jobType', {
                  is: (v) => unemployed.includes(Number(v)),
                  then: string(),
                  otherwise: string().required('Requerido'),
                }),
                economicSector: string().when('jobType', {
                  is: (v) => unemployed.includes(Number(v)),
                  then: string(),
                  otherwise: string().required('Requerido'),
                }),
                paymentMethod: string().when('jobType', {
                  is: (v) => unemployed.includes(Number(v)),
                  then: string(),
                  otherwise: string().required('Requerido'),
                }),
                paymentPeriodicity: string().when('jobType', {
                  is: (v) => unemployed.includes(Number(v)),
                  then: string(),
                  otherwise: string().required('Requerido'),
                }),
                monthlySalary: number().when(
                  'jobType',
                  (jobType: number, scheme: NumberSchema) => {
                    const min =
                      minIncome[findOption(mifos)('jobType', jobType).label];
                    if (employee.includes(Number(jobType))) {
                      return scheme
                        .required('Requerido')
                        .min(
                          min,
                          `Tu salario debe ser igual o mayor a ${moneyFormat(
                            min
                          )}`
                        );
                    }

                    if (
                      independent.includes(Number(jobType)) ||
                      student.includes(Number(jobType))
                    ) {
                      return scheme
                        .required('Requerido')
                        .min(
                          min,
                          `Tus ingresos deben ser iguales o mayores a ${moneyFormat(
                            min
                          )}`
                        );
                    }

                    if (pensioner.includes(Number(jobType))) {
                      return scheme
                        .required('Requerido')
                        .min(
                          min,
                          `Tu pensión debe ser igual o mayor a ${moneyFormat(
                            min
                          )}`
                        );
                          }
                    return scheme;
                  }
                ),

              })}
              onSubmit={submitTrap(async(laboral) => {
                setFormValues((prev) => ({ ...prev, laboral }));
                try {
                  if (!laboral.jobType){
                  throw new FormFieldsError({
                    jobType: 'Selecciona el tipo de empleo',
                  });
                  }
                  if (laboral.economicSector > 0) {
                    laboral.economicSector = parseInt(
                      `${laboral.economicSector}`,
                      10
                    );
                  }
                  await submit({ ...formValues, laboral });
                } catch (e) {
                  if (e instanceof FormFieldsError) {
                    throw new FormFieldsError({ jobType: 'Selecciona el tipo de empleo' });       
                  } else {
                    console.error('Error al enviar el formulario:', e);
                  }
                }
              })}
            >
              <Form>
                <Title divider>Información laboral</Title>
                <GeneralContent>
                  <FormGroup>
                    <Label>Tipo de empleo</Label>
                    <InputSelect name="jobType" options={mifos.jobType} />
                  </FormGroup>

                  <FormChange
                    visible={(v) => employee.includes(Number(v.jobType))}
                  >
                    <InputGroup
                      label="Compañía donde trabajas"
                      name="company"
                    />
                  </FormChange>

                  <FormChange
                    visible={(v) => independent.includes(Number(v.jobType))}
                  >
                    <InputGroup
                      label="NIT de la empresa (solo si eres emprendedor)"
                      maxLength={40}
                      name="nit"
                    />
                    <FormGroup>
                      <Label>Ocupación</Label>
                      <InputSelect
                        options={mifos.economicActivity}
                        name="economicActivity"
                      />
                    </FormGroup>
                  </FormChange>
                  <FormChange
                    visible={(v) =>
                      v.jobType !== '' &&
                      !unemployed.includes(Number(v.jobType))
                    }
                  >
                    <FormGroup>
                      <Label>Sector económico</Label>
                      <InputSelect
                        name="economicSector"
                        options={mifos.economicSector.filter(
                          (o) => !isNone(o.label)
                        )}
                      />
                    </FormGroup>
                  </FormChange>
                  <FormChange
                    visible={(v) => independent.includes(Number(v.jobType))}
                  >
                    <FormGroup>
                      <Label>Otros ingresos</Label>
                      <InputSelect
                        name="otherIncome"
                        help="* Opcional"
                        placeholder=" "
                        options={mifos.otherIncome}
                      />
                    </FormGroup>
                  </FormChange>

                  <FormChange
                    visible={(v) =>
                      v.jobType !== '' &&
                      !unemployed.includes(Number(v.jobType))
                    }
                  >
                    <FormGroup>
                      <Label>Banco donde te consignan la nómina/ingresos</Label>
                      <InputSelect
                        name="bank"
                        options={mifos.bank.filter((o) => !isNone(o.label))
                          .slice()
                          .sort((a, b) => a.label.localeCompare(b.label))}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Salario/ingreso mensual</Label>
                      <InputNumber name="monthlySalary" icon="dollar-sign" />
                    </FormGroup>

                    <FormGroup>
                      <Label>
                        ¿Cómo recibes la mayor parte de tus ingresos?
                      </Label>
                      <InputSelect
                        name="paymentMethod"
                        options={mifos.paymentMethod}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Periodicidad de pago</Label>
                      <InputSelect
                        name="paymentPeriodicity"
                        options={mifos.paymentPeriodicity.filter(
                          (o) => !isNone(o.label)
                        )}
                      />
                    </FormGroup>
                    
                  </FormChange>
                  <MessageEnd>Al hacer clic en continuar con mi solicitud, 
                    iniciaremos la consulta de tu historial en centrales de
                    riesgo e información, de acuerdo a la autorización que nos otorgaste.
                  </MessageEnd>
                </GeneralContent>
                <GroupButton>
                  <ButtonContainer>
                    <Button flat onClick={preventDefault(wizard.decrease)}>
                      Anterior
                    </Button>
                  </ButtonContainer>
                  <ButtonContainer>
                    <FormSubmitPI>
                      Continuar mi solicitud
                    </FormSubmitPI>
                  </ButtonContainer>
                </GroupButton>
              </Form>
            </Formik>
          </Wizard.Step>
        </Wizard.Steps>
      </GeneralContent>
    </Column>
  );
};

export default PersonalInformationView;
