import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Mount from '../../../components/Mount';
import buroAccountsGet from '../../../services/buroAccountsGet';
import eventTypeGet, { UserDesenbplsingMethods } from '../../../services/eventTypeGet';
import repaymentGet from '../../../services/repaymentGet';
import { Option, OptionNumber } from '../../../services/selectorsGet';
import { JTPState } from '../../../store';
import SignatureView from './SignatureView';

export interface Props extends RouteComponentProps {
  banks: Option[];
  accountTypes: Option[];
  fullName: string;
  email: string;
  amount: number;
  term: number;
  loanId: string;
  loanStatus: string;
  filterEventType: (value: UserDesenbplsingMethods[]) => OptionNumber[];
}

const SignatureContainer: React.FC<Props> = (props) => {
  return (
    <Mount
      before={async () => {
        const { status: statusEventType, payload: eventTypeList } =
          await eventTypeGet();

        const eventTypeEnabled =
          statusEventType === 'loaded'
            ? props.filterEventType(eventTypeList.udm)
            : [];

        const { payload } = await buroAccountsGet();
        const accounts: Option[] = payload.accounts.map((v) => ({
          value: v,
          label: `---- ---- ---- ${v}`,
        }));

        accounts.push({ value: -1, label: 'Otra cuenta' });

        const repayment = await repaymentGet({
          paymentId: '1',
        });

        return {
          eventTypeEnabled,
          accounts,
          repayment: repayment.payload,
        };
      }}
      props={props}
      component={SignatureView}
    />
  );
};

export default connect((state: JTPState, ownProps) => ({
  banks: state.mifos.bank,
  filterEventType: (eventTypeEnabled: UserDesenbplsingMethods[]) => {
    const codes = eventTypeEnabled.map((evt) => evt.dmId);
    return state.mifos.eventType
      .filter((evt) => codes.includes(evt.value))
      .map((evt: OptionNumber) => ({
        ...evt,
        selected: eventTypeEnabled.filter((ev) => ev.dmId === evt.value)[0]
          .selected,
      }));
  },
  accountTypes: state.mifos.accountTypeId,
  term: state.loan.term,
  amount: state.loan.amount,
  loanId: state.loan.id,
  loanStatus: state.loan.status,
  ...state.user.user,
  ...ownProps,
}))(SignatureContainer);
