import debounce from 'lodash.debounce';
import serviceBuilder from './serviceBuilder';

export const url = 'sec/users/buro';

export default debounce(
  serviceBuilder<{ jobType: number }>('post', {
    url,
    auth: true,
  }),
  1000,
  { leading: true }
);
