import None from '../types/None';
import serviceBuilder from './serviceBuilder';

export interface PersonalInfo {
  dependents: string;
  genderId: number;
  expeditionDate: string;
  birthdate: string;
  maritalStatus: number;
  educationalLevel: number;
  issueDepartmentCode: string;
  issueCityCode: string;
}

export interface ResidentialInfo {
  housingType: number;
  stayTime: number;
  address: string;
  cityCode: string;
  departmentCode: string;
  neighborhood: string;
}

export interface LaboralInfo {
  jobType: number;
  monthlySalary: number;
  bank: number;
  company: string;
  nit: string;
  paymentPeriodicity: number;
  economicActivity: number;
  otherIncome: number;
  economicSector: number;
  paymentMethod: number;
}



export type BasicInfoPlain = PersonalInfo &
  ResidentialInfo &
  LaboralInfo
 

export type BasicInfo = {
  personal: PersonalInfo;
  laboral: LaboralInfo;
  residential: ResidentialInfo;
 
};

export interface Error {
  errors: string[];
  message?: string;
}

export const url = 'sec/users/pi';

export default serviceBuilder<BasicInfoPlain, None>('post', {
  url,
  auth: true,
});
