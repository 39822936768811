import { date, number, object, string } from 'yup';
import toDayjs from '../../../helpers/toDayjs';
import { BasicInfo } from '../../../services/basicInfoPost';

const maxDate = new Date();
const minDate = new Date(maxDate.getFullYear() - 80, 1, 1);

const over18 = new Date();
over18.setFullYear(maxDate.getFullYear() - 18);

const invalidDate = new Date('');

const dateSchema = () =>
  date()
    .transform(function (value, rawValue) {
      try {
        if (this.isType(rawValue)) {
          return rawValue;
        }
        const date = toDayjs(rawValue, 'DD-MM-YYYY');
        value = date.isValid() ? date.toDate() : invalidDate;
        return value;
      } catch {}
    })
    .min(minDate, 'Fecha no válida')
    .max(maxDate, 'Fecha no válida')
    .typeError('Formato de fecha no válido')
    .required('Requerido');


const selectSchema = string().required('Requerido') as any as any;
const validationSchema = {
  personal: object<BasicInfo['personal']>({
    expeditionDate: dateSchema() as any,
    // cross validation behavior undefined
    // .when(
    //   'birthdate',
    //   (v: Date, schema: DateSchema) => {
    //     if (!v || v === invalidDate) return schema;
    //     return schema.min(
    //       dayjs(v)
    //         .add(17, 'year')
    //         .toDate(),
    //       'Fecha de expedición no válida'
    //     );
    //   }
    // ) as any,
    birthdate: dateSchema().max(over18, 'Debe ser mayor de 18 años') as any,
    genderId: selectSchema,
    dependents: selectSchema,
    educationalLevel: selectSchema,
    maritalStatus: selectSchema,
    issueDepartmentCode: selectSchema,
    issueCityCode: selectSchema,
  }),
  residential: object<BasicInfo['residential']>({
    housingType: selectSchema,
    stayTime: selectSchema,
    address: string().required('Requerido'),
    cityCode: string().required('Requerido'),
    departmentCode: string().required('Requerido'),
    neighborhood: string().required('Requerido'),
  }),
  laboral: object<BasicInfo['laboral']>({
    jobType: number(),
    monthlySalary: number(),
    bank: number(),
    company: string(),
    nit: string(),
    economicActivity: number(),
    paymentPeriodicity: number(),
    otherIncome: number(),
    economicSector: number(),
    paymentMethod: number(),
  })
};

export default validationSchema;
