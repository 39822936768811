/* eslint react-hooks/exhaustive-deps: 0 */
import { Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { boolean, object, string } from 'yup';
import ErrorFocus from '../../../components/Form/ErrorFocus';
import Form from '../../../components/Form/Form';
import FormChange from '../../../components/Form/FormChange';
import FormError from '../../../components/Form/FormError';
import FormGroup from '../../../components/Form/FormGroup';
import FormSubmit from '../../../components/Form/FormSubmit';
import { formats } from '../../../components/Form/Input';
import InputAccountNumber from '../../../components/Form/InputAccountNumber';
import InputCheckbox from '../../../components/Form/InputCheckbox';
import InputGroup from '../../../components/Form/InputGroup';
import InputRadio from '../../../components/Form/InputRadio';
import InputSelect from '../../../components/Form/InputSelect';
import Label from '../../../components/Form/Label';
import Title from '../../../components/Form/Title';
import Column from '../../../components/Grid/Column';
import { Block, Flex } from '../../../components/Grid/Flex';
import Button from '../../../components/UI/Button';
import ButtonContainer from '../../../components/UI/ButtonContainer';
import ButtonGroup from '../../../components/UI/ButtonGroup';
import GeneralContent from '../../../components/UI/GeneralContent';
import LabelMessage from '../../../components/UI/LabelMessages';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import AvalAdvice from '../../../documents/AvalAdvice';
import AvalAndPlatformAdvice from '../../../documents/AvalAndPlatformAdvice';
import PlatformAdvice from '../../../documents/PlatformAdvice';
import { submitTrap } from '../../../helpers/formHelpers';
import moneyFormat from '../../../helpers/moneyFormat';
import noop from '../../../helpers/noop';
import bankInformationPost from '../../../services/bankInformationPost';
import basicInfoPatch from '../../../services/basicInfoPatch';
import loanDisclaimerPost, { Terms } from '../../../services/loanDisclaimerPost';
import loanSignaturePost from '../../../services/loanSignaturePost';
import MethodIdPost from '../../../services/methodIdPost';
import { Repayment } from '../../../services/repaymentGet';
import { Option, OptionNumber } from '../../../services/selectorsGet';
import { Autorizo } from './Autorizo';
import { Aval } from './Aval';
import { Costos } from './Costos';
import { Garantias } from './Garantias';
import { Mutuo } from './Mutuo';
import { Plataforma } from './Plataforma';
import { Poder } from './Poder';

const P = styled.p`
  text-align: justify;
`;
interface EmailForm {
  email: string;
}

interface MethodIdForm {
  disbursementMethodId: string;
}

const ContainValues = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.grayLight};
  border: 1px solid ${(props) => props.theme.grayLight};
  border-radius: 1rem;
  padding: 0.75rem;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-weight: 14px;
`;

const AccountInitialValues = {
  bank: '' as any,
  account: '' as any,
  accountEndsWith: '',
  accountType: '' as any,
  automaticDebit: true,
};

const TermsInitialValues = {
  costsAndExpenses: true,
  aval: true,
  platform: true,
  promissory: true,
  powerIrrevocable: true,
  guarantees: true,
  automaticDebit: true,
};

const InitialValues = {
  account: AccountInitialValues,
  terms: TermsInitialValues,
  disbursementMethodId: '',
  email: '',
  fullName: '',
};

type FormData = typeof InitialValues;

const formDataReducer = (state: FormData, action: any): FormData => {
  return {
    ...state,
    ...action,
  };
};

type Advice = 'aval' | 'platform' | 'avalAndPlatform';

const ErrorMessage = {
  main: <p>Ocurrio un error mientras se enviaba tu información.</p>,
  accept: 'Aceptar',
};

const ErrorMessageConfig = (msj: string) => ({
  main: <p>{msj}</p>,
  accept: 'Aceptar',
});

const FinishMessage = (email: string) => ({
  main: (
    <p>
      Se ha enviado un correo electrónico a <b>{email}</b> con copia de los
      contratos y las instrucciones para que los firmes.
    </p>
  ),
  accept: 'Aceptar',
});

export interface Props extends RouteComponentProps {
  banks: Option[];
  accountTypes: Option[];
  accounts: Option[];
  fullName: string;
  email: string;
  amount: number;
  loanStatus: string;
  term: number;
  repayment: Repayment;
  eventTypeEnabled: OptionNumber[];
}

const accountSchema = object({
  bank: string().required('Seleccione un banco'),
  accountEndsWith: string().required('Seleccione una cuenta válida'),
  accountType: string().required('Requerido'),
  account: string()
    .required('Requerido')
    .test('ends-with', 'error', function (value) {
      const { accountEndsWith } = this.parent;
      if (!accountEndsWith) {
        return this.createError({
          path: 'accountEndsWith',
          message: 'Selecciona una cuenta',
        });
      }
      if (accountEndsWith * 1 === -1) {
        return true;
      }

      if (
        !new RegExp(`.*${accountEndsWith.slice(-4)}$`).test(value)
      ) {
        return this.createError({
          message: (
            <span>
              La cuenta debe finalizar en: <b>{accountEndsWith.slice(-4)}</b>
            </span>
          ) as any,
        });
      }
      return true;
    }),
});

const SignatureView: React.FC<Props> = ({
  history,
  banks,
  accountTypes,
  accounts,
  email,
  fullName,
  amount,
  term,
  repayment,
  eventTypeEnabled,
  loanStatus,
}) => {
  const [formData, updateFormData] = React.useReducer(
    formDataReducer,
    InitialValues
  );

  const [modal, openModal] = useModalC();
  const [showConfirmEmail, setShowConfirmEmail] = React.useState(false);
  const [showMethod, setShowMethod] = React.useState(false);
  const [showAdvice, setAdvice] = React.useState<Advice>();

  const accountFormRef = React.useRef<Formik<any>>({} as any);
  const termsFormRef = React.useRef<Formik<any>>({} as any);

  useEffect(() => {

    if (loanStatus === 'Approved and awaiting signature') {
      setShowConfirmEmail(true);
    }
  }, []);

  const submitFirm = React.useCallback(async () => {
    try {
      await accountFormRef.current.submitForm();
      await termsFormRef.current.submitForm();
    } catch {
      /** ignore error */
    }

    const isValid = (form: Formik) => {
      return Object.values(form.getFormikBag().errors).length === 0;
    };

    if (!isValid(accountFormRef.current) || !isValid(termsFormRef.current)) {
      return;
    }

    if (!formData.terms.aval && !formData.terms.platform) {
      return setAdvice('avalAndPlatform');
    }

    if (!formData.terms.aval) {
      return setAdvice('aval');
    }

    if (!formData.terms.platform) {
      return setAdvice('platform');
    }

    if (!formData.disbursementMethodId) {
      return setShowMethod(true);
    }

    setShowConfirmEmail(true);
  }, [formData, accountFormRef, termsFormRef]);

  const submitAll = useCallback(async () => {
    const { account, terms, disbursementMethodId } = formData;

    if (loanStatus !== 'Approved and awaiting signature') {
      account.account = account.account.replace(/\s+/g, '');
      account.accountType *= 1;
      account.bank *= 1;
      account.automaticDebit = terms.automaticDebit;

      await bankInformationPost(account);

      await MethodIdPost({
        disbursementMethodId: parseInt(disbursementMethodId, 10),
      });
    }

    try {
      await basicInfoPatch({ email: formData.email.trim() });
    } catch (e) {
      await openModal(ErrorMessageConfig(e.errors.email));
      return;
    }

    try {
      await loanSignaturePost({
        url: window.location.origin + '/loans/predisburse',
      });
      delete terms.automaticDebit;
      await loanDisclaimerPost(terms);
      terms.automaticDebit = true;
    } catch {
      await openModal(ErrorMessage);
    }

    await openModal(FinishMessage(formData.email));
    history.push('/logout');
  }, [formData]);

  if (showAdvice) {
    return (
      <Column>
        <GeneralContent>
          <h2>Firmar tu contrato</h2>
          <br />
          {showAdvice === 'aval' && <AvalAdvice username={fullName} />}
          {showAdvice === 'platform' && <PlatformAdvice username={fullName} />}
          {showAdvice === 'avalAndPlatform' && (
            <AvalAndPlatformAdvice username={fullName} />
          )}
          <Flex row justify="space-around">
            <ButtonContainer>
              <Button onClick={() => setAdvice(undefined)}>Regresar</Button>
              <Button
                onClick={() => {
                  setShowConfirmEmail(true);
                  setAdvice(undefined);
                }}
              >
                Continuar
              </Button>
            </ButtonContainer>
          </Flex>
        </GeneralContent>
      </Column>
    );
  }

  if (showMethod) {
    return (
      <Formik
        onSubmit={() => {
          setShowMethod(false);
          setShowConfirmEmail(true);
        }}
        initialValues={{
          ...formData,
          disbursementMethodId:
            eventTypeEnabled.find((evt) => evt.selected)?.value.toString() ||
            '',
        }}
        validationSchema={object<MethodIdForm>({
          disbursementMethodId: string().required('Campo requerido'),
        })}
      >
        <GeneralContent>
          <h2>Confirma cómo quieres hacer uso de tu crédito</h2>
          <Form>
            <FormChange onChange={(value) => updateFormData(value)} />
            {eventTypeEnabled.map((item: OptionNumber) => {
              return (
                <div key={item.value}>
                  <FormGroup>
                    <InputRadio
                      name="disbursementMethodId"
                      value={item.value}
                      label={item.label}
                      description={item.description}
                    />
                  </FormGroup>
                </div>
              );
            })}
            <ButtonGroup align="center">
              <ButtonContainer>
                <FormSubmit>Siguiente</FormSubmit>
              </ButtonContainer>
            </ButtonGroup>
          </Form>
        </GeneralContent>
      </Formik>
    );
  }

  if (showConfirmEmail) {
    return (
      <Column>
        <GeneralContent>
          <ModalC props={modal} />
          <p>
            Confirma el correo donde quieres recibir el link para firmar los
            contratos.
          </p>
          <Formik
            initialValues={{ email }}
            validationSchema={object<EmailForm>({
              email: string()
                .trim()
                .email('Formato no valido')
                .required('Campo obligatorio'),
            })}
            onSubmit={submitTrap(submitAll as any)}
          >
            <Form>
              <FormChange onChange={(value) => updateFormData(value)} />
              <InputGroup
                name="email"
                type="email"
                icon="envelope"
                format={formats.email}
                placeholder="example@email.com"
              />
              <ButtonContainer>
                <FormSubmit>Enviar</FormSubmit>
              </ButtonContainer>
            </Form>
          </Formik>
        </GeneralContent>
      </Column>
    );
  }

  return (
    <Column>
      <GeneralContent>
        <h2>Confirma tus datos bancarios</h2>

        <Column>
          <Formik
            ref={accountFormRef}
            initialValues={formData.account}
            validateOnChange
            validationSchema={accountSchema}
            onSubmit={noop}
          >
            <Form>
              <GeneralContent>
                <FormChange
                  onChange={(account: Account) => {
                    updateFormData({ account });
                  }}
                />
                <FormError></FormError>
                <FormGroup>
                  <Label>
                    Selecciona el banco en el que quieres que te desembolsemos
                    tu crédito:
                  </Label>
                  <FormGroup>
                    <InputSelect
                      name="bank"
                      placeholder="Seleccione un Banco"
                      options={banks
                        .slice() 
                        .sort((a, b) => a.label.localeCompare(b.label)) 
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>La Cuenta contiene esta secuencia:</Label>
                    <InputSelect
                      name="accountEndsWith"
                      defaultOption=""
                      placeholder="Selecciona una cuenta"
                      options={accounts}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Confirma tu cuenta:</Label>
                    <InputAccountNumber name="account" />
                  </FormGroup>
                  <FormGroup>
                    <InputSelect
                      name="accountType"
                      placeholder="Tipo de cuenta"
                      options={accountTypes}
                    />
                  </FormGroup>
                </FormGroup>
              </GeneralContent>
              <ErrorFocus></ErrorFocus>
            </Form>
          </Formik>
        </Column>

        <Column>
          <GeneralContent>
            <Title>Valores de crédito </Title>
            <p>
              <b>
                Por favor verifique que los valores del Monto y Plazo son
                correctos
              </b>
            </p>

            <Flex justify="space-between" nowrap>
              <ContainValues>
                <Block style={{ marginRight: 30, fontWeight: 'bold' }}>
                  <Label>Monto:</Label>
                  <Label>Desembolso:</Label>
                  <Label>Plazo:</Label>
                  <Label>Valor cuota:</Label>
                  <Label>Primer cuota:</Label>
                </Block>
                <Block>
                  <Label>{moneyFormat(amount)}</Label>
                  <Label>
                    {moneyFormat(
                      amount - repayment.feeChargesAtDisbursementCharged
                    )}
                  </Label>
                  <Label>{term} meses</Label>
                  <Label>{moneyFormat(repayment.repaymentAmount)}</Label>
                  <Label>{repayment.date}</Label>
                </Block>
              </ContainValues>
            </Flex>
            {/* <Button
              sm
              outline
              color="red"
              onClick={async () => {
                try {
                  await loanSignaturePatch();
                  await openModal(ModifyValues);
                  history.push('/logout');
                } catch {}
              }}
            >
              DESEO MODIFICAR EL MONTO O PLAZO
            </Button> */}
          </GeneralContent>
        </Column>

        <Column>
          <GeneralContent>
            <Title>
              <P>
                Aceptas que te enviemos a tu email los siguientes contratos para
                tu firma electrónica:
              </P>
            </Title>
          </GeneralContent>
          <Formik
            ref={termsFormRef}
            initialValues={formData.terms}
            validateOnChange
            validationSchema={object({
              costsAndExpenses: boolean().oneOf([true]),
              aval: boolean(),
              platform: boolean(),
              promissory: boolean().oneOf([true]),
              powerIrrevocable: boolean().oneOf([true]),
              guarantees: boolean().oneOf([true]),
              automaticDebit: boolean().oneOf([true], 'Requerido'),
            })}
            onSubmit={noop}
          >
            <Form>
              <GeneralContent>
                <ModalC props={modal} />
                <FormChange
                  onChange={(terms: Terms) => {
                    updateFormData({ terms });
                  }}
                />
                <FormGroup>
                  <InputCheckbox hideError name="costsAndExpenses">
                    <LabelMessage onClick={() => openModal(Costos)}>
                      Los costos y gastos
                    </LabelMessage>
                  </InputCheckbox>
                </FormGroup>
                <FormGroup>
                  <InputCheckbox name="aval">
                    <LabelMessage onClick={() => openModal(Aval)}>
                      Aval (opcional)
                    </LabelMessage>
                  </InputCheckbox>
                </FormGroup>
                <FormGroup>
                  <InputCheckbox name="platform">
                    <LabelMessage onClick={() => openModal(Plataforma)}>
                      Servicio de firma electrónica (opcional)
                    </LabelMessage>
                  </InputCheckbox>
                </FormGroup>
                <FormGroup>
                  <InputCheckbox hideError name="promissory">
                    <LabelMessage onClick={() => openModal(Mutuo)}>
                      Mutuo y Pagaré
                    </LabelMessage>
                  </InputCheckbox>
                </FormGroup>
                <FormGroup>
                  <InputCheckbox hideError name="powerIrrevocable">
                    <LabelMessage onClick={() => openModal(Poder)}>
                      Poder irrevocable
                    </LabelMessage>
                  </InputCheckbox>
                </FormGroup>
                <FormGroup>
                  <InputCheckbox hideError name="guarantees">
                    <LabelMessage onClick={() => openModal(Garantias)}>
                      Garantías mobiliarias
                    </LabelMessage>
                  </InputCheckbox>
                </FormGroup>
                <FormGroup>
                  <InputCheckbox name="automaticDebit">
                    <LabelMessage onClick={() => openModal(Autorizo)}>
                      <u>Débito automático</u>
                    </LabelMessage>
                  </InputCheckbox>
                </FormGroup>
              </GeneralContent>
              <P>
                <small>
                  Enviaremos copia de los contratos antes de tu firma a tu
                  correo para que los revises en detalle. Enviaremos un “link
                  único de firma” que es para efectos legales tu firma
                  electrónica.
                </small>
              </P>
            </Form>
          </Formik>
          <ButtonGroup align="center">
            <ButtonContainer>
              <Button type="submit" onClick={submitFirm}>
                Siguiente
              </Button>
            </ButtonContainer>
          </ButtonGroup>
        </Column>
      </GeneralContent>
    </Column>
  );
};

export default SignatureView;
